import React from 'react';
import styled from 'styled-components';
import { Link, graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// components
import { Navbar, Footer, Banner } from 'src/components/common';
import { SectionHeader } from 'src/components/Photography';

const listings = [
  {
    name: 'ashesi venture incubator',
    path: 'coperate-events',
    fullWidth: true,
  },

  {
    name: 'cocktail & Co',
    path: 'cocktail-co',
    fullWidth: true,
  },

  {
    name: 'essie spice cooking class',
    path: 'esi-spice-cooking-class',
  },

  {
    name: 'Beacon International School',
    sub: 'Graduation',
    path: 'becon-intl-sch',
  },

  {
    name: 'the garden party',
    path: 'garden-party',
  },
];

const EventsPage = (props) => {
  return (
    <>
      <Navbar dark />
      <StyledSubSectionLayout
        className="container"
        style={{
          marginTop: '100px',
        }}
      >
        <SectionHeader sectionName="Events" />

        <div className="listing">
          {listings.map((item, i) => {
            const path = item.path ? item.path : item.name.toLowerCase().split(' ').join('-');

            return (
              <StyledBackgroundSection
                key={i}
                name={path}
                className={`${item.fullWidth && 'list-middle-item'} list-item `}
              >
                <Link to={`${props.path}${path}`}>
                  <h2>{item.name}</h2>
                  {item.sub && <p>{item.sub}</p>}
                </Link>
              </StyledBackgroundSection>
            );
          })}
        </div>
      </StyledSubSectionLayout>

      <Banner />
      <Footer />
    </>
  );
};

const BackgroundSection = ({ className, children, name }) => (
  <StaticQuery
    query={graphql`
      query {
        all: allFile(filter: { relativePath: { regex: "/photography/events/home/" } }) {
          nodes {
            name
            childImageSharp {
              fluid(maxWidth: 1240) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.all.nodes.find((node) => node.name === name);

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData.childImageSharp.fluid}
          backgroundColor={`#fff`}
          color="black"
        >
          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            {children}
          </div>
        </BackgroundImage>
      );
    }}
  />
);

const StyledSubSectionLayout = styled.section`
  min-height: 100vh;

  .listing {
    margin-bottom: 200px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-template-rows: repeat(2, auto);
    grid-gap: 30px;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
    }

    .list-middle-item {
      grid-column-start: span 2;
    }

    .list-item {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 400px;
      }

      :hover {
        filter: brightness(87.5%);
      }

      h2 {
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 2rem;
        padding: 0 20px;
        background: rgba(0, 0, 0, 1);
        text-align: center;
        color: white;
        padding: 5px 10px;
      }

      p {
        background: rgba(0, 0, 0, 1);
        color: white;
        padding: 5px 10px;
        text-align: center;
      }
    }
  }
`;

const StyledBackgroundSection = styled(BackgroundSection)`
  background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

export default EventsPage;
